import React from "react";
import zahnArrowPurple from "../../../assets/logos/zahn/zahnArrowPurple.webp";
import { vacancy } from "./Careers";

export const Vacancy = ({
  vacancies,
  description,
}: {
  vacancies: vacancy[];
  description: string | React.JSX.Element;
}) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center border-b-2 border-light-grey py-4 my-4 font-bold">
        <h3 className="flex flex-col items-center  lg:flex-row text-2xl text-dark-grey">
          <span className="text-dark-purple text-5xl lg:text-2xl px-2">
            X{vacancies.length}
          </span>
          <span className="text-lg lg:text-2xl">
            {" "}
            {`${vacancies.length === 1 ? "vacancy" : "vacancies"}`} available
          </span>
        </h3>
        <p
          className={`w-full lg:w-1/2 py-4 text-sm lg:text-lg font-normal ${vacancies.length > 0 ? "lg:py-10" : ""}`}
        >
          {description}
        </p>
      </div>
      <ul>
        {vacancies.map((vacancy: vacancy) => (
          <li
            key={vacancy.id}
            className="w-full flex items-start relative border-2 focus:outline-none focus:ring-none border-x-white border-t-white border-b-light-grey py-4"
          >
            <div className="flex flex-col">
              <img
                src={zahnArrowPurple}
                alt="ZAHN safety icon in purple"
                className="w-10 h-auto"
              />
              <div className="flex flex-col lg:flex-row w-full">
                <div className="w-full lg:w-2/3 text-dark-grey">
                  <h3 className="lg:py-1 text-xl font-bold text-dark-purple">
                    {vacancy.title}
                  </h3>

                  <ul className="flex flex-col text-lg lg:text-xl font-normal leading-snug lg:leading-normal">
                    <li className="lg:italic lg:py-1">{vacancy.hours}</li>
                    <li className="lg:italic lg:py-1">
                      {vacancy.experienceLevel}
                    </li>
                    <li className="py-6 lg:py-4 text-sm lg:text-base ">
                      {vacancy.description}
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-1/3 flex flex-col items-center lg:items-end">
                  <div className="flex flex-col items-center lg:items-end">
                    <div className="w-full flex flex-col items-end">
                      <a
                        href={vacancy.link}
                        target="_blank"
                        rel="noreferrer"
                        className="w-full flex flex-col items-center lg:items-end py-1"
                      >
                        <button className=" bg-dark-purple hover:bg-white w-full lg:w-3/4 font-bold hover:text-dark-purple  text-white lg:p-3 p-2 rounded-full border-2 hover:border-2 hover:border-dark-purple transition duration-700 text-lg lg:text-base">
                          Apply now
                        </button>
                      </a>
                    </div>

                    <p className="w-3/4 pt-2 lg:pt-0 pb-6 lg:pb-0 lg:py-2 text-dark-purple text-center text-xs lg:text-sm">
                      Please get in touch to obtain more information about this
                      crucial role.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
