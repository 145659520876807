import facebookLogoWhite from "../../assets/logos/socials/facebookLogoWhite.webp";
import xLogoWhite from "../../assets/logos/socials/xLogoWhite.webp";
import instaSvgLogoWhite from "../../assets/logos/socials/instagramLogoWhite.png";

export const Socials = () => {
  return (
    <div>
      <ul className="flex py-8 lg:py-1">
        <li>
          <a
            href="https://twitter.com/zahn_app"
            aria-label="Link to ZAHN's Twitter/X and opens in new tab"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-8 h-8 mx-1 p-0.5"
              src={xLogoWhite}
              alt="X/Twitter logo in white"
            ></img>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/zahnapp.ldn/"
            aria-label="Link to ZAHN's Instagram and opens in new tab"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-8 h-8 ml-1.5"
              src={instaSvgLogoWhite}
              alt="Instagram logo in white"
            ></img>
          </a>
        </li>

        <li>
          <a
            href="https://www.facebook.com/zahnapp/"
            aria-label="Link to ZAHN's Facebook and opens in new tab"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-8 h-8 m"
              src={facebookLogoWhite}
              alt="Facebook logo in white"
            ></img>
          </a>
        </li>
      </ul>
    </div>
  );
};
