import { EmailSignUp } from "../../shared/email-signup/EmailSignUp";

export const Hero = () => {
  return (
    <div className="h-1/2 -mb-[120px] pb-[100px] lg:min-h-screen w-full text-center flex flex-col bg-cover bg-center bg-hero-image-mobile md:bg-hero-image px-8 lg:px-12 pt-20 lg:pt-[200px] preload-bg">
      <div className="flex justify-center lg:w-full">
        <div className="flex flex-col items-center pt-6 divide-y divide-white w-full md:w-3/4 lg:w-3/4 xl:w-1/2">
          <h2 className="text-white text-center text-2xl lg:text-[45px] lg:leading-[50px] font-bold pb-6">
            Working to make the world a safer place to live and thrive.
          </h2>
          <div className="flex flex-col justify-center">
            <h3 className="text-white text-lg lg:text-[25px] lg:leading-[35px] font-bold pt-8 pb-2 lg:pb-0 lg:pt-8 ">
              Sign up to be the first to hear when we launch!
            </h3>
            <EmailSignUp />
          </div>
        </div>
      </div>
    </div>
  );
};
