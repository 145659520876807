import { Menu, Tab } from "@headlessui/react";
import { classNamesJoin } from "../../../helpers/classNamesJoin";
import React, { Fragment, useState } from "react";
import { Vacancy } from "./Vacancy";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export interface vacancy {
  id: number;
  title: string;
  hours: string;
  experienceLevel: string;
  description: string | React.JSX.Element;
  link: string;
}

export type VacanciesByCareerCategory = {
  [key: string]: {
    description: string;
    vacancies: vacancy[];
  };
};

const vacanciesByCareerCategory: VacanciesByCareerCategory = {
  Product: {
    description: "",
    vacancies: [],
  },
  Tech: {
    description:
      "The goals of the tech team at ZAHN is to revolutionise the safety app market. We are looking for experienced, motivated and innovative individuals to join our passionate developers and engineers to focus on creating a seamless, user-friendly experience that prioritises safety. Driven by creativity, innovation, and a commitment to excellence, ensuring our app stands out in the crowded market and becomes an indispensable tools for users.",
    vacancies: [
      {
        id: 1,
        title: "Machine Learning Specialist",
        hours: "Full time & Part time available",
        experienceLevel: "Mid - Senior Level",
        description: (
          <p>
            As a Machine Learning Specialist at ZAHN, you will be pivotal in
            developing and optimising the algorithms that power our innovative
            app. Your responsibilities include designing, training, and
            deploying machine learning models to analyse complex data sets.{" "}
            <br /> <br />
            You&apos;ll collaborate with data engineers and product teams to
            integrate ML solutions seamlessly into our app. Your expertise in
            data science, statistical analysis, and algorithm development will
            drive our product&apos;s efficiency.{" "}
          </p>
        ),
        link: "mailto:lily@zahnapp.com",
      },
    ],
  },
  Marketing: {
    description: "",
    vacancies: [],
  },
  "Customer Success": {
    description: "",
    vacancies: [],
  },
  Sales: {
    description: "",
    vacancies: [],
  },
  Operations: {
    description: "",
    vacancies: [],
  },
  "Human Resources": {
    description: "",
    vacancies: [],
  },
};

export const Careers = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>("Tech");
  const [isButtonRotated, setIsButtonRotated] = useState<boolean>(false);
  const totalVacancies = (): number => {
    let totalVacancies = 0;

    for (const category in vacanciesByCareerCategory) {
      totalVacancies += vacanciesByCareerCategory[category].vacancies.length;
    }

    return totalVacancies;
  };
  const totalVacanciesCount = totalVacancies();

  return (
    <div className="flex flex-col justify-center items-center lg:p-10 font-poppins">
      <h2 className="text-3xl lg:text-[45px] lg:leading-[50px] text-dark-purple font-bold my-6 lg:my-0 py-6 px-4 lg:px-0 lg:pt-[50px] text-center">
        Let&apos;s Innovate Together
      </h2>
      <h3 className="hidden lg:flex flex-col items-center lg:flex-row lg:pb-12 lg:pt-1 text-2xl text-dark-grey font-bold">
        <span className="text-dark-purple text-5xl lg:text-2xl px-2">
          X{totalVacanciesCount}
        </span>
        <span className="text-lg lg:text-2xl lg:text-[30px] lg:leading-[40px]">
          {`${totalVacanciesCount === 1 ? "vacancy" : "vacancies"}`} available
        </span>
      </h3>
      <div className="flex flex-col justify-center items-center w-full px-8 lg:px-0 lg:hidden">
        <Menu>
          <div className="relative w-full">
            <Menu.Button
              onClick={() => {
                setIsButtonRotated(!isButtonRotated);
              }}
              className="flex justify-center items-center w-full align-baseline p-3 border rounded-full bg-dark-grey text-white font-bold text-xl"
            >
              <span className="flex-grow text-center">{selectedCategory}</span>
              <ChevronDownIcon
                className={`absolute right-4 w-8 h-8 transition duration-700 ${!isButtonRotated ? "rotate-180 " : null}`}
              />
            </Menu.Button>
          </div>
          <Menu.Items className="flex flex-col w-full">
            <div className="flex flex-col text-center justify-center">
              {Object.keys(vacanciesByCareerCategory).map((category, index) => (
                <Menu.Item key={index} as={Fragment}>
                  {({ active }) => (
                    <a
                      className={`${active ? "bg-gray-100" : ""} cursor-pointer p-2`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      {category}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
        <div className="w-full">
          {Object.entries(vacanciesByCareerCategory)
            .filter(([category]) => category === selectedCategory)
            .map(([, { vacancies, description }], index: number) => (
              <div
                key={index}
                className="lg:py-4 focus:outline-none focus:ring-none"
              >
                <Vacancy vacancies={vacancies} description={description} />
              </div>
            ))}
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-col w-full">
        <Tab.Group defaultIndex={1}>
          <Tab.List className="flex bg-white pt-4">
            {Object.keys(vacanciesByCareerCategory).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNamesJoin(
                    "w-full py-2.5 text-lg leading-5 font-bold",
                    "border-b-2 border-t-white border-x-white focus:outline-none focus:ring-none transition",
                    selected
                      ? "bg-white text-dark-purple border-b-dark-purple border-t-white border-x-white mx-0 px-0 focus:outline-none focus:ring-none duration-700"
                      : " hover:text-dark-purple border-b-light-grey duration-700",
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="">
            {Object.values(vacanciesByCareerCategory).map(
              ({ vacancies, description }, index: number) => (
                <Tab.Panel
                  key={index}
                  className="py-4 focus:outline-none focus:ring-none"
                >
                  <Vacancy vacancies={vacancies} description={description} />
                </Tab.Panel>
              ),
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
