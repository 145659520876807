import { Dialog } from "@headlessui/react";
import zahnArrowPurple from "../../../assets/logos/zahn/zahnArrowPurple.webp";

type Props = {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (isOpen: boolean) => void;
};

export const EmailSubmitted = ({ isOpen, setIsOpen }: Props) => {
  return (
    <Dialog
      className="fixed z-10003 bg-white"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="fixed inset-0 bg-black opacity-80" aria-hidden="true" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-full max-w-sm transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
            <div className="flex flex-end justify-end">
              <button
                type="button"
                className="flex flex-end justify-center  focus:outline-none focus-visible:ring-2 focus-visible:ring-dark-purple focus-visible:ring-offset-2"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <Dialog.Title
              as="h3"
              className="text-2xl lg:text-[30px] lg:leading-[40px] pb-4  text-center text-dark-purple leading-6 font-bold"
            >
              Thank you!
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-lg lg:text-[22.5px] lg:leading-[35px] text-center font-bold text-dark-grey">
                You have now joined the ZAHN community.
              </p>
              <p className="text-sm lg:text-[17.5px] lg:leading-[20px] text-dark-grey text-center py-4">
                We&apos;ll be in touch with updates soon.
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={zahnArrowPurple}
                alt="ZAHN triangle logo in purple"
                className="w-14"
              />
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
