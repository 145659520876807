import zahnAppLogo from "../../../assets/logos/zahn/zahnAppLogo.webp";
import appStoreLogos from "../../../assets/logos/other/appStoreIcons.png";
import React from "react";

export interface CareersProps {
  careersRef: React.RefObject<HTMLDivElement>;
}

export const LaunchStatement: React.FC<CareersProps> = ({ careersRef }) => {
  return (
    <div className="pt-7 md:pt-9 lg:pt-12 w-full text-center flex flex-col justify-center items-center">
      <div>
        <img
          src={zahnAppLogo}
          alt="ZAHN app logo - purple square with rounded edges and a white ZAHN triangle"
          className="w-22 h-16"
        />
      </div>
      <h2 className="text-2xl lg:text-[30px] lg:leading-[40px]  text-white font-bold py-6 px-12 lg:px-0">
        LAUNCHING IN LONDON FIRST.
      </h2>
      <h3 className="text-dark-purple lg:py-4 pb-2 text-sm lg:text-[20px] lg:leading-[30px] lg:pb-0 font-semibold">
        AVAILABLE ON ALL PLATFORMS SOON
      </h3>
      <img
        src={appStoreLogos}
        alt="Android Play store logo and Apple iOS store logos side by side"
        className="w-3/4 md:w-1/3 h-22 lg:h-22 lg:w-1/4 pb-6 lg:pb-10"
        loading="lazy"
      />
      <span ref={careersRef} className="pb-10 opacity-0 invisible">
        .
      </span>
    </div>
  );
};
