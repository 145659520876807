import candidCallerText from "../../../assets/logos/candid-caller/CC-STACKED-1.png";
import zahnCC from "../../../assets/images/zahn-cc.webp";

export const CandidCaller = () => {
  return (
    <div className="min-h-[1000px] md:h-[1400px] lg:h-[1700px] lg:mb-10 w-full bg-cover bg-center md:bg-bottom bg-candid-caller-sisters-mobile lg:bg-candid-caller-sisters-laptop">
      <div className="px-8 flex flex-col justify-center items-center font-helvetica-700">
        <img
          className="pt-18 mt-12 w-2/3 md:w-1/2 lg:w-1/5"
          src={candidCallerText}
          alt="Candid Caller podcast logo"
        />
        <div className="text-center text-xs lg:text-sm font-[500] py-2 w-1/2">
          <p className="text-cc-red leading-none tracking-widest">
            HOSTED BY SISTERS
          </p>
          <p className="text-white leading-none tracking-widest">
            LILY & ROSE MACKIE
          </p>
        </div>
        <div className="text-base md:text-lg lg:text-2xl text-center font-semibold py-2 w-full">
          <p className="text-white leading-tight">
            We&apos;re launching our new podcast series
          </p>
          <p className="text-cc-red leading-tight">
            <em>Candid&nbsp;Caller,</em>
          </p>
          <p className="text-white leading-tight">
            Anonymous&nbsp;Calls:&nbsp;Unveiling Untold&nbsp;Stories.
          </p>
        </div>
        <div className="lg:w-1/2 3xl:w-[55%] lg:px-20 text-center text-xs md:text-sm lg:text-base">
          <p className="text-white pt-2">
            Candid Caller strives to create a global community, dedicated to
            shedding light on the experiences of those affected by crime. Our
            podcast offers a unique platform for anonymous phone calls,
            providing a safe space for individuals to tell their story candidly
            and without fear of judgement.
          </p>
        </div>

        <button
          type="button"
          aria-label="Submit your untold story - links to the Candid Caller website"
          className="bg-white border border-white rounded-full w-3/4 md:w-1/3 lg:w-1/5 py-2 my-3 lg:my-6 text-sm lg:text-base text-cc-red hover:text-white hover:border-cc-red  transition duration-700 font-bold hover:bg-cc-red"
        >
          <a href="https://candidcaller.co.uk" target="_blank" rel="noreferrer">
            Submit your untold story
          </a>
        </button>
        <img
          className="w-1/4 md:w-1/6 lg:w-[110px] lg:h-7 my-2"
          src={zahnCC}
          alt="Brought to you by ZAHN"
        />
      </div>
    </div>
  );
};
