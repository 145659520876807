import { useState } from "react";

import { handleEmailClick } from "../../../helpers/emailZahn";

interface Faq {
  question: string;
  answer: string;
}

export const FAQs = ({ faqsRef }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const faqs: Faq[] = [
    {
      question: "When will ZAHN launch on the app store?",
      answer:
        "The official launch date has not been released to the public yet. Sign up to get notified as soon as we are ready!",
    },
    {
      question: "Where can I learn more about ZAHN's technology?",
      answer:
        "Our technology will be revealed on our launch date! If you have any specific questions or concerns please get in touch with us directly.",
    },
    {
      question: "What is BETA testing?",
      answer:
        "Our selected BETA testing participants will the first group to use ZAHN. They will have the opportunity to assist our team in uncovering any bugs or issues before it is released to the public. ",
    },
    {
      question: "How will I know if I've been selected?",
      answer:
        "We will be selecting a small group of participants to join us in creating the best safety app. We will directly contact you either via email or phone.",
    },
    {
      question: "How can I find out more about investing?",
      answer:
        "We would be thrilled to talk to you about investing in our startup! Please contact us at hello@zahnapp.com and we will be able to share more information.",
    },
  ];

  const toggleAnswerVisibility = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="pt-28 w-full flex flex-col lg:justify-center items-center">
      <div
        ref={faqsRef}
        className="bg-white w-full border-white rounded-[85px] lg:rounded-[180px] lg:py-[100px] lg:w-full flex flex-col justify-center items-center px-4 py-10"
      >
        <h2 className="py-6 text-dark-purple font-bold text-3xl lg:text-[45px] lg:leading-[50px]">
          FAQs
        </h2>
        <ul className="lg:w-1/2 divide-y divide-dark-purple divide-dotted">
          {faqs.map((faq: Faq, index) => {
            return (
              <li
                className="py-6 flex flex-col cursor-pointer"
                key={index}
                onClick={() => toggleAnswerVisibility(index)}
              >
                <div className="flex w-full items-center">
                  <div
                    className="w-1/4"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="https://www.w3.org/2000/svg"
                      xmlnsXlink="https://www.w3.org/1999/xlink"
                      x="36"
                      y="36"
                      viewBox="0 0 1190.55 841.89"
                      enableBackground={"new 0 0 1190.55 841.89"}
                      xmlSpace="preserve"
                      className={`w-8 h-6 lg:w-10 lg:h-8  ${openIndex === index ? "transition duration-700 rotate-180" : "rotate-90"}`}
                      style={{
                        fill:
                          openIndex === index || hoveredIndex === index
                            ? "#751aff"
                            : "#575059",
                      }}
                    >
                      <style>{`.st0{clip-path:url(#SVGID_2_);}`}</style>
                      <g>
                        <defs>
                          <rect
                            id="SVGID_1_"
                            x="33.92"
                            y="122.67"
                            width="1123.17"
                            height="595.67"
                          />
                        </defs>
                        <clipPath id="SVGID_2_">
                          <use
                            xlinkHref="#SVGID_1_"
                            style={{ overflow: "visible" }}
                          />
                        </clipPath>
                        <path
                          className="st0"
                          d="M462.53,128.8L37.06,692.14c-7.67,10.17-0.42,24.71,12.3,24.71h191.16c4.82,0,9.38-2.26,12.29-6.11
        l340.03-448.52l3.91-5.17l0.77,0.97l125.17,166h-0.38L868.33,618.2l67.3,92.32c2.91,3.97,7.54,6.33,12.47,6.33h193.54
        c12.71,0,19.97-14.51,12.35-24.67L731.46,128.84c-2.91-3.87-7.47-6.17-12.33-6.17h-244.3C469.99,122.66,465.43,124.94,462.53,128.8
      "
                        />
                        <path
                          className="st0"
                          d="M541.46,479.3L368.54,708.29c-3.11,4.13-0.17,10.05,5,10.05h77.69c1.96,0,3.81-0.92,4.99-2.48l138.2-182.32
        l1.59-2.1l0.31,0.39l50.87,67.47h-0.16l59.34,78.93l27.35,37.53c1.18,1.61,3.07,2.58,5.07,2.58h78.66c5.16,0,8.12-5.9,5.02-10.03
        L650.76,479.32c-1.18-1.57-3.03-2.51-5.01-2.51h-99.29C544.49,476.81,542.64,477.74,541.46,479.3"
                        />
                      </g>
                    </svg>
                  </div>

                  <p className="font-bold lg:font-bold text-dark-purple w-3/4 lg:text-[22.5px] lg:leading-[35px]">
                    {faq.question}
                  </p>
                </div>
                <div className="w-full flex flex-row-reverse">
                  {openIndex === index && (
                    <p
                      className={`text-dark-grey text-sm lg:text-[20px] lg:leading-[30px] w-3/4 py-2`}
                    >
                      {faq.answer.includes("hello@zahnapp.com") ? (
                        <>
                          We would be thrilled to talk to you about investing in
                          our startup! Please contact us at{" "}
                          <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleEmailClick}
                          >
                            hello@zahnapp.com
                          </a>{" "}
                          and we will be able to share more information.
                        </>
                      ) : (
                        <p>{faq.answer}</p>
                      )}
                    </p>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
