import React, { useEffect, useState } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { AppLevelProps } from "../../../App";
import xLogoPurple from "../../../assets/logos/socials/xLogoPurple.webp";
import xLogoWhite from "../../../assets/logos/socials/xLogoWhite.webp";
import zahnWhiteWritten from "../../../assets/logos/zahn/zahnWhiteWritten.webp";
import zahnPurpleWritten from "../../../assets/logos/zahn/zahnPurpleWritten.webp";
import facebookLogoPurple from "../../../assets/logos/socials/facebookLogoPurple.webp";
import facebookLogoWhite from "../../../assets/logos/socials/facebookLogoWhite.webp";
import instagramLogoPurple from "../../../assets/logos/socials/instagramLogoPurple.webp";
import instagramLogoWhite from "../../../assets/logos/socials/instagramLogoWhite.png";
import { Twirl as Hamburger } from "hamburger-react";
import { handleEmailClick } from "../../../helpers/emailZahn";

export const NavBar: React.FC<AppLevelProps> = ({
  ourMissionSectionRef,
  getInvolvedRef,
  candidCallerRef,
  faqsRef,
  careersRef,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navMap = {
    "Our Mission": ourMissionSectionRef,
    "How to get involved": getInvolvedRef,
    "Candid Caller Podcast": candidCallerRef,
    FAQs: faqsRef,
    Careers: careersRef,
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const initialScrollY = window.scrollY;
    if (initialScrollY > 100) {
      setIsScrolled(true);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleHomeClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header className="fixed w-full z-[10000] top-0 left-0 font-bold cursor-pointer">
      <nav
        className={`flex items-center transition duration-300 justify-between px-8 lg:py-2 lg:px-12 ${isScrolled ? "text-dark-purple" : "text-white"} ${isScrolled ? "bg-white" : null}`}
        aria-label="Global"
      >
        <div onClick={handleHomeClick} className="w-full lg:w-1/5">
          <h1 aria-label="ZAHN"></h1>
          <img
            className="w-28 h-18 lg:w-[120px] lg:h-auto"
            src={isScrolled ? zahnPurpleWritten : zahnWhiteWritten}
            alt="ZAHN logo written"
          />
        </div>

        {/* mobile view */}
        <div className="flex lg:hidden">
          <span className="sr-only">Open main menu</span>
          <Hamburger
            color={!mobileMenuOpen && !isScrolled ? "white" : "#751aff"}
            size={22}
            toggled={mobileMenuOpen}
            toggle={setMobileMenuOpen}
            aria-label="Open/close main menu"
          />
        </div>

        {/* opening 3 bar button on mobile view */}
        <Transition.Root show={mobileMenuOpen} as="div">
          <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 overflow-hidden">
              <Dialog.Panel className="bg-white text-2xl h-fit fixed right-0 w-full py-8 px-8 sm:max-w-sm">
                <Transition.Child
                  as="div"
                  enter="transition-transform duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition-transform duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="flex w-full justify-end">
                    <Hamburger
                      color={mobileMenuOpen && !isScrolled ? "none" : "#751aff"}
                      size={22}
                      toggled={mobileMenuOpen}
                      toggle={setMobileMenuOpen}
                      aria-label="Open/close main menu"
                    />
                  </div>
                  <div className="flow-root">
                    <div className="divide-y divide-gray-500/10">
                      <div className="space-y-2">
                        <Disclosure as="div">
                          {() => (
                            <>
                              <ul className="text-dark-purple pt-5 cursor-pointer">
                                {Object.entries(navMap).map(
                                  ([listItem, listItemValue]) => {
                                    return (
                                      <li
                                        key={listItem}
                                        onClick={() => {
                                          setMobileMenuOpen(false);
                                          if (listItemValue.current) {
                                            listItemValue.current.scrollIntoView(
                                              { behavior: "smooth" },
                                            );
                                          }
                                        }}
                                        className="p-1 font-bold"
                                      >
                                        {listItem ===
                                        "Candid Caller Podcast" ? (
                                          <>
                                            {listItem}{" "}
                                            <span className="text-cc-red text-[10px] translate-y-[-40%] absolute">
                                              NEW
                                            </span>
                                          </>
                                        ) : (
                                          listItem
                                        )}
                                      </li>
                                    );
                                  },
                                )}
                                <hr className="text-dark-grey border-0.5 border-black my-3"></hr>
                                <li
                                  className="text-dark-purple text-xl py-2 pb-2"
                                  key={"email"}
                                >
                                  <a
                                    href="#"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={handleEmailClick}
                                  >
                                    hello@zahnapp.com
                                  </a>
                                </li>
                              </ul>
                              <div>
                                <ul className="flex pt-2">
                                  <li>
                                    <a
                                      href="https://www.instagram.com/zahnapp.ldn/"
                                      aria-label="Link to ZAHN's Instagram and opens in new tab"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        className="w-8 h-8 mr-2"
                                        src={instagramLogoPurple}
                                        alt="ZAHN logo purple"
                                      ></img>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://twitter.com/zahn_app"
                                      aria-label="Link to ZAHN's Twitter/X and opens in new tab"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        className="w-8 h-8 mr-0 p-0.5"
                                        src={xLogoPurple}
                                        alt="X/Twitter logo"
                                      ></img>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.facebook.com/zahnapp/"
                                      aria-label="Link to ZAHN's Facebook and opens in new tab"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        className="w-8 h-8 mr-2"
                                        src={facebookLogoPurple}
                                        alt="Facebook logo"
                                      ></img>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </Dialog.Panel>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="hidden lg:flex">
          <ul className="p-2 flex flex-row text-lg font-bold cursor-pointer">
            {Object.entries(navMap).map(([listItem, listItemValue]) => {
              return (
                <li
                  key={listItem}
                  onClick={() => {
                    if (listItemValue.current) {
                      listItemValue.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="px-6 hover:underline"
                >
                  {listItem === "Candid Caller Podcast" ? (
                    <>
                      {listItem}{" "}
                      <span className="text-cc-red text-[10px] translate-y-[-40%] absolute">
                        NEW
                      </span>
                    </>
                  ) : (
                    listItem
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        {/* nav menu on laptop view */}
        <div className="hidden lg:flex lg:w-1/5">
          <ul className={`text-sm flex items-center`}>
            <li>
              <a
                href="#"
                target="_blank"
                className="hover:underline mr-3"
                rel="noopener noreferrer"
                onClick={handleEmailClick}
              >
                hello@zahnapp.com
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/zahn_app"
                aria-label="Link to ZAHN's Twitter/X and opens in new tab"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-6 h-6 mx-1 p-0.5"
                  src={isScrolled ? xLogoPurple : xLogoWhite}
                  alt="X/Twitter logo"
                ></img>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/zahnapp.ldn/"
                aria-label="Link to ZAHN's Instagram and opens in new tab"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-6 h-6 ml-1 mr-0.5"
                  src={isScrolled ? instagramLogoPurple : instagramLogoWhite}
                  alt="Instagram logo"
                ></img>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/zahnapp/"
                aria-label="Link to ZAHN's Facebook and opens in new tab"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-6 h-6 mx-1"
                  src={isScrolled ? facebookLogoPurple : facebookLogoWhite}
                  alt="Facebook logo"
                ></img>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
