import { createBrowserRouter } from "react-router-dom";
import { App } from "../App";

export const ourMissionUrl = "ourmission";
export const getInvolvedUrl = "getinvolved";
export const candidCallerUrl = "candidcaller";
export const faqsUrl = "faqs";
export const careersUrl = "careers";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App scrollToSection={""} />,
  },

  {
    path: `/${ourMissionUrl}`,
    element: <App scrollToSection={ourMissionUrl} />,
  },
  {
    path: `/${getInvolvedUrl}`,
    element: <App scrollToSection={getInvolvedUrl} />,
  },
  {
    path: `/${candidCallerUrl}`,
    element: <App scrollToSection={candidCallerUrl} />,
  },
  {
    path: `/${faqsUrl}`,
    element: <App scrollToSection={faqsUrl} />,
  },
  {
    path: `/${careersUrl}`,
    element: <App scrollToSection={careersUrl} />,
  },
]);
