import React from "react";
import zahnTriangleGrey from "../../../assets/logos/zahn/zahnArrowGrey.webp";
import { Socials } from "../Socials";
import { EmailSignUp } from "../email-signup/EmailSignUp";
import { LegalLinks } from "../LegalLinks";
import { ContactLinks } from "../ContactLinks";
import { CareersProps } from "../../views/faqs/LaunchStatement";
import { FooterProps } from "../../../App";

export const Footer: React.FC<CareersProps & FooterProps> = ({
  careersRef,
  footerRef,
}) => {
  return (
    <footer ref={footerRef} className="bg-dark-purple text-white font-poppins">
      <div className="pt-8 px-0 lg:p-10 flex flex-col justify-center items-center transition duration-700 transform translate-x-0">
        <div className="flex flex-col lg:w-11/12 lg:flex-row items-center lg:items-start justify-between">
          <div className="flex flex-col">
            <h2 className="font-bold  text-center lg:text-left text-md lg:text-[25px] lg:leading-[35px]">
              Get in touch
            </h2>
            <div className="hidden lg:flex lg:flex-col lg:flex-start">
              <ContactLinks />
              <Socials />
            </div>
            <div className="flex lg:hidden">
              <Socials />
            </div>
          </div>
          <div className="hidden lg:flex flex-col lg:w-1/3 items-center justify-center">
            <h4 className="text-center text-white font-bold lg:pb-2 lg:text-[25px] lg:leading-[35px]">
              Sign up now!
            </h4>
            <p className="py-4">Be the first to hear when we launch</p>
            <EmailSignUp />
          </div>
          <div className="hidden lg:flex self-center">
            <LegalLinks careersRef={careersRef} />
          </div>
        </div>

        <div className="flex w-full px-2 md:w-3/4 justify-evenly md:justify-center items-center lg:hidden">
          <ContactLinks />
          <LegalLinks careersRef={careersRef} />
        </div>

        <div className="flex flex-col items-center pb-6 pt-4">
          <img
            className="w-10 h-auto"
            src={zahnTriangleGrey}
            alt="ZAHN triangle grey colour"
            loading="lazy"
          ></img>
          <p className="text-xs lg:text-[12px] lg:leading-[18px] opacity-50">
            © ZAHN 2024
          </p>
        </div>
      </div>
    </footer>
  );
};
