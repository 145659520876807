import axios from "axios";
import { useState } from "react";
import "../../../types/environment.d.ts";

import { EmailSubmitted } from "../email-signup/EmailSubmitted";
import { EmailSubmitButton } from "../email-signup/EmailSubmitButton";

export const EmailSignUp = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleEmailSubmit = async (event: any) => {
    event.preventDefault();
    try {
      // eslint-disable-next-line no-undef
      await axios.post(`${process.env.REACT_APP_API_URL}/api/submitemail`, {
        email: currentEmail,
      });
      setCurrentEmail("");
      setError(false);
      setIsOpen(true);
    } catch (error) {
      setIsOpen(false);
      setError(true);
    }
  };

  const handleEmailChange = (event: any) => {
    const emailString = event.target.value as string;
    const emailLowercase = emailString.toLowerCase();
    setCurrentEmail(emailLowercase);
  };

  return (
    <>
      <form
        className="flex flex-col w-full items-center"
        onSubmit={handleEmailSubmit}
      >
        <div className="flex w-full lg:my-4 ">
          <input
            className="focus:outline-none text-sm md:text-base lg:text-[20px] lg:leading-[30px] font-bold text-center rounded-l-full text-dark-purple w-full my-2 py-2 p-2 placeholder-opacity-75 placeholder-dark-purple"
            placeholder="Enter your email"
            name="email"
            value={currentEmail}
            onChange={handleEmailChange}
          ></input>
          <EmailSubmitButton />
        </div>
        {error ? (
          <p className="text-white text-xs lg:text-[14px] lg:leading-[20px] text-center">
            ❌ Unable to sign up. Please check your email address is valid
          </p>
        ) : (
          <></>
        )}
        <p className="text-white text-[8px] lg:text-xs my-2 text-center">
          By signing up, you agree to ZAHNs Privacy Notice
        </p>
      </form>
      <EmailSubmitted isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
