import { useEffect, useState } from "react";
import zahnArrowGrey from "../../assets/logos/zahn/zahnArrowGrey.webp";
import zahnArrowPurple from "../../assets/logos/zahn/zahnArrowPurple.webp";
import { FooterProps } from "../../App";
import React from "react";

export const ScrollToTopBtn: React.FC<FooterProps> = ({ footerRef }) => {
  const [showScrollToTopBtn, setShowScrollToTopBtn] = useState(false);
  const [isOnFooter, setisOnFooter] = useState(false);

  const scrollToTop = () => {
    setShowScrollToTopBtn(false);
    return window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    const topOfFooter = footerRef.current
      ? footerRef.current?.getBoundingClientRect().y + window.scrollY - 750
      : 8000;
    if (topOfFooter) {
      if (
        footerRef.current &&
        window.scrollY > 300 &&
        window.scrollY < topOfFooter
      ) {
        setShowScrollToTopBtn(true);
        setisOnFooter(false);
      } else if (
        footerRef.current &&
        window.scrollY > 300 &&
        window.scrollY >= topOfFooter
      ) {
        setShowScrollToTopBtn(true);
        setisOnFooter(true);
      } else {
        setisOnFooter(false);
        setShowScrollToTopBtn(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return showScrollToTopBtn ? (
    <div
      className="hidden lg:flex lg:flex-col justify-center items-center fixed bottom-8 right-10 lg:bottom-12 lg:right-20 z-50"
      onClick={scrollToTop}
    >
      <button
        type="button"
        className={`motion-safe:hover:animate-bounce flex flex-col justify-center lg:w-14 lg:h-14 ${isOnFooter ? "bg-white" : "bg-dark-purple"} rounded-full p-2`}
      >
        <img
          alt="ZAHN arrow in grey colour"
          src={`${isOnFooter ? zahnArrowPurple : zahnArrowGrey}`}
        />
      </button>
      <p
        className={`${isOnFooter ? "text-white" : "text-dark-purple"} text-sm pt-1 font-bold`}
      >
        Top
      </p>
    </div>
  ) : (
    <></>
  );
};
