import React from "react";
import privacyPolicyPdf from "../../assets/documents/privacy-notice.pdf";
import cookiePdf from "../../assets/documents/cookies.pdf";
import { CareersProps } from "../views/faqs/LaunchStatement";

export const LegalLinks: React.FC<CareersProps> = ({ careersRef }) => {
  const handleCareerClick = () => {
    if (careersRef && careersRef.current) {
      careersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="flex flex-col justify-center lg:py-0 md:py-6 ml-4 md:ml-8 lg:ml-0 text-sm lg:text-[17.5px] lg:leading-[25px] md:w-auto text-white opacity-[60%]">
      <ul className="flex flex-col">
        <li
          className="py-1 hover:underline cursor-pointer"
          onClick={handleCareerClick}
        >
          {" "}
          Careers
        </li>
        <li className="py-1 hover:underline ">
          <a href={privacyPolicyPdf} target="_blank" rel="noreferrer">
            {" "}
            Privacy Notice
          </a>
        </li>
        <li className="py-1 hover:underline">
          <a href={cookiePdf} target="_blank" rel="noreferrer">
            {" "}
            Cookies
          </a>{" "}
        </li>
      </ul>
    </div>
  );
};
