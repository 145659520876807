import zahnArrowPurpleOutline from "../../../assets/logos/zahn/zahnArrowPurpleOutlinePointingRight.webp";

export const DiscoverUs = () => {
  return (
    <div className="bg-white py-6 lg:h-fit flex flex-col justify-center items-center">
      <div className="flex justify-center items-center my-5">
        <a
          href="https://www.instagram.com/zahnapp.ldn/"
          aria-label="Link to ZAHN's Instagram and opens in new tab"
          target="_blank"
          rel="noreferrer"
          className="flex justify-center items-center "
        >
          <h2 className="text-dark-grey font-bold lg:text-2xl lg:text-[30px] lg:leading-[40px]">
            Discover us
          </h2>
          <img
            src={zahnArrowPurpleOutline}
            alt="ZAHN logo with purple outline"
            className="w-7 h-10 mx-2 lg:mx-4 lg:w-14 lg:h-20"
            loading="lazy"
          />
          <p className="font-bold text-dark-purple lg:text-2xl lg:text-[30px] lg:leading-[40px]">
            @zahnapp.ldn
          </p>
        </a>
      </div>{" "}
      <div className="hidden lg:flex lg:flex-col justify-center px-10 items-center bg-white w-full my-5">
        <iframe
          src="//lightwidget.com/widgets/75a33d22b0d75c3e80607ba0a834b97b.html"
          className="flex justify-center items-baseline my-10 h-[220px] xl:h-[300px] w-full"
        ></iframe>
      </div>
    </div>
  );
};
