import { useEffect, useState } from "react";
import zahnArrowGrey from "../../../assets/logos/zahn/ZAHN-Arrow-Grey.webp";
import zahnArrowPurple from "../../../assets/logos/zahn/zahnArrowPurple.webp";

export const EmailSubmitButton = () => {
  const [isOnFooter, setIsOnFooter] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleScroll = () => {
    window.scrollY > 1000 ? setIsOnFooter(true) : null;
  };

  useEffect(() => {
    const initialScrollY = window.scrollY;
    if (initialScrollY > 1000) {
      setIsOnFooter(true);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return !isOnFooter ? (
    <button
      className={`flex justify-center rounded-r-full bg-dark-purple hover:bg-white hover:border-white hover:border-l-dark-purple border-dark-purple border-r-dark-purple border-y-dark-purple  border w-1/5 my-2 py-2 lg:py-3 pl-1 pr-2 font-bold`}
      type="submit"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? zahnArrowPurple : zahnArrowGrey}
        alt="Zahn triangle in grey pointing right"
        className={`w-6 h-6 lg:w-8 lg:h-8  ${isHovered ? "rotate-90" : "p-0.5"}`}
      />
    </button>
  ) : (
    <button
      className={`flex justify-center rounded-r-full hover:bg-dark-purple hover:border-white hover:border bg-white border-dark-purple border border-r-white border-y-white w-1/5 my-2 py-2 lg:py-3 pl-1 pr-2 font-bold`}
      type="submit"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? zahnArrowGrey : zahnArrowPurple}
        alt="Zahn triangle in grey pointing right"
        className={`w-6 h-6 lg:w-8 lg:h-8  ${isHovered ? "p-0.5" : "rotate-90"}`}
      />
    </button>
  );
};
