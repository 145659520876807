import React, { useEffect, useRef } from "react";
import { NavBar } from "./components/shared/nav/NavBar";
import { Hero } from "./components/views/hero/Hero";
import { Footer } from "./components/shared/footer/Footer";
import { OurMissionAndTech } from "./components/views/mission-and-tech/OurMissionAndTech";
import { GetInvolved } from "./components/views/get-involved/GetInvolved";
import { CandidCaller } from "./components/views/candid-caller/CandidCaller";
import { FAQs } from "./components/views/faqs/FAQs";
import { ZahnVideo } from "./components/views/zahn-video/ZahnVideo";
import { ScrollToTopBtn } from "./components/shared/ScrollToTopBtn";
import { LaunchStatement } from "./components/views/faqs/LaunchStatement";
import { DiscoverUs } from "./components/views/discover-us/DiscoverUs";
import { Careers } from "./components/views/careers/Careers";
import {
  ourMissionUrl,
  getInvolvedUrl,
  candidCallerUrl,
  faqsUrl,
  careersUrl,
} from "./router/Router";

export interface AppLevelProps {
  ourMissionSectionRef: React.RefObject<HTMLDivElement>;
  getInvolvedRef: React.RefObject<HTMLDivElement>;
  candidCallerRef: React.RefObject<HTMLDivElement>;
  faqsRef: React.RefObject<HTMLDivElement>;
  careersRef: React.RefObject<HTMLDivElement>;
  footerRef?: React.RefObject<HTMLDivElement>;
}

export interface FooterProps {
  footerRef: React.RefObject<HTMLDivElement>;
}

export const App = ({ scrollToSection }: { scrollToSection: string }) => {
  const ourMissionSectionRef = useRef<HTMLDivElement>(null);
  const getInvolvedRef = useRef<HTMLDivElement>(null);
  const candidCallerRef = useRef<HTMLDivElement>(null);
  const faqsRef = useRef<HTMLDivElement>(null);
  const careersRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const refs: Record<string, React.MutableRefObject<null | HTMLDivElement>> = {
    [ourMissionUrl]: ourMissionSectionRef,
    [getInvolvedUrl]: getInvolvedRef,
    [candidCallerUrl]: candidCallerRef,
    [faqsUrl]: faqsRef,
    [careersUrl]: careersRef,
  };

  useEffect(() => {
    const scrollToRef:
      | React.MutableRefObject<null | HTMLDivElement>
      | undefined = refs[scrollToSection];

    if (scrollToRef) {
      if (scrollToSection === careersUrl || scrollToSection === ourMissionUrl) {
        window.scrollTo({
          top:
            (scrollToRef.current?.getBoundingClientRect().top ?? 1) +
            window.scrollY +
            20,
        });
      } else {
        window.scrollTo({
          top:
            (scrollToRef.current?.getBoundingClientRect().top ?? 1) +
            window.scrollY,
        });
      }
    }
    // hacky but low priority for now - disabled as no more dependencies needed for this to function
    // eslint-disable-next-line
  }, [careersRef.current]);

  return (
    <>
      <main>
        <NavBar
          ourMissionSectionRef={ourMissionSectionRef}
          getInvolvedRef={getInvolvedRef}
          candidCallerRef={candidCallerRef}
          faqsRef={faqsRef}
          careersRef={careersRef}
        />

        <section className="lg:min-h-screen">
          <Hero />
        </section>
        <section
          className="min-h-screen mt-14 lg:mt-20"
          ref={ourMissionSectionRef}
        >
          <OurMissionAndTech />
          <ZahnVideo />
        </section>
        <div
          className="bg-gradient-to-r from-gradient-start via-gradient-middle to-gradient-end lg:-mt-[260px] -mt-[160px]"
          ref={getInvolvedRef}
        >
          <section className="min-h-screen -mb-[100px] lg:-mb-0 lg:pt-20 pb-14">
            <GetInvolved />
          </section>
          <section
            className="min-h-screen lg:-mt-[100px] md:h-[1400px] lg:h-[1900px] pt-20 pb-24 lg:pb-14 mb-10"
            ref={candidCallerRef}
          >
            <CandidCaller />
          </section>
          <section className="min-h-screen -mt-[160px]" ref={faqsRef} id="faqs">
            <FAQs />
            <LaunchStatement careersRef={careersRef} />
          </section>
        </div>
        <section className="text-dark-grey" id="careers">
          <Careers />
        </section>
        <section className="flex flex-col">
          <DiscoverUs />
        </section>
      </main>
      <Footer careersRef={careersRef} footerRef={footerRef} />
      <ScrollToTopBtn footerRef={footerRef} />
    </>
  );
};
